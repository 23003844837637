html, body {
  margin: 0;
  padding: 0;
}

html {
  background-color: #fdf5e6;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  font-family: Work Sans, sans-serif;
}

#flexcontainer {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#left {
  flex: 1;
  max-width: 500px;
  padding-top: 80px;
  padding-right: 10px;
}

#right {
  flex: 2;
  max-width: 500px;
  padding: 80px 40px 40px;
}

@media screen and (width >= 800px) {
  #flexcontainer {
    flex-direction: row;
  }

  #left {
    padding: 40px;
  }

  #right {
    max-width: none;
  }
}

ul {
  list-style: none;
}

ul li {
  padding-bottom: 20px;
}

.greytext {
  color: #3c3c3c;
}

.title {
  font-size: 1.2em;
  font-weight: 700;
}

.description {
  padding-left: 20px;
}

#infocard {
  transform-origin: 50%;
  background-color: #ff9;
  margin-top: 30px;
  padding: 1rem 0;
  transform: rotate(-5deg)translateX(1rem)translateY(-1rem);
  box-shadow: 0 5px 5px #00000026;
}

#social {
  justify-content: center;
  display: flex;
}

#social i {
  margin: 0 15px 20px;
}

#name {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.7em;
  font-weight: 600;
}

#email {
  text-align: center;
  font-size: 1.1em;
}

a {
  color: inherit;
  text-decoration: underline;
}

h1 {
  margin-bottom: 50px;
}
/*# sourceMappingURL=index.666014a8.css.map */
