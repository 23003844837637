html,
body {
  margin: 0;
  padding: 0;
}

html {
  background-color: oldlace;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Work Sans', sans-serif;
}

#flexcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#left {
  flex: 1;
  padding-right: 10px;
  padding-top: 80px;
  max-width: 500px;
}

#right {
  flex: 2;
  padding: 40px;
  padding-top: 80px;
  max-width: 500px;
}

@media screen and (min-width: 800px) {
  #flexcontainer {
    flex-direction: row;
  }

  #left {
    padding: 40px;
  }

  #right {
    max-width: none;
  }
}

ul {
  list-style: none;
  li {
    padding-bottom: 20px;
  }
}

.greytext {
  color: #3c3c3c;
}

.title {
  font-weight: 700;
  font-size: 1.2em;
}

.description {
  padding-left: 20px;
}

#infocard {
  background-color: #ffff99;
  transform: rotate(-5deg) translateX(1rem) translateY(-1rem);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem 0;
  transform-origin: 50% 50%;
  margin-top: 30px;
}

#social {
  display: flex;
  justify-content: center;

  i {
    margin: 0 15px 20px;
  }
}

#name {
  font-weight: 600;
  font-size: 1.7em;
  text-align: center;
  margin-bottom: 20px;
}

#email {
  font-size: 1.1em;
  text-align: center;
}

a {
  text-decoration: underline;
  color: inherit;
}

h1 {
  margin-bottom: 50px;
}
